import "./Header.css";

export const Header = ({
  darkMode,
  nameClass,
  logoClass,
}: {
  darkMode: boolean;
  nameClass: string;
  logoClass: string;
}) => {
  return (
    <nav className={"navbar header-navbar " + (darkMode ? "header-dark" : "")}>
      <div className={"navbar-group navbar-left name-logo " + nameClass}>
        <button
          className="text"
          onClick={() => document.getElementById("main-box").scrollTo(0, 0)}
          title="Go to top"
        >
          <div className="d-flex flex-column align-items-center">
            cameron wu
          </div>
        </button>
      </div>
      <div className={"navbar-group navbar-right links-logo " + logoClass}>
        <span className="navbar-link">
          {/* LinkedIn */}
          <a
            href="https://www.linkedin.com/in/camwunz/"
            title="LinkedIn"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </span>
        {/* <span className="navbar-link">
          <a href="mailto:camwunz@gmail.com" title="Email">
            <i className="fa-solid fa-envelope"></i>
          </a>
        </span> */}
        <span className="navbar-link">
          {/* GitHub */}
          <a
            href="https://github.com/camwunz/"
            title="Github"
            rel="noopener noreferrer"
            target="_blank"
          >
            <i className="fa-brands fa-github"></i>
          </a>
        </span>
        <span className="navbar-link">
          {/* TODO: CV */}
          <a
            href="/CV"
          >
            <i className="fa-regular fa-file-lines"></i>
          </a>
        </span>
      </div>
    </nav>
  );
};
