import "./Home.css";
import { Header } from "./Header/Header";
import { Introduction } from "./Introduction/Introduction";
import { useEffect, useState } from "react";

export const Home = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);
  const [nameClass, setNameClass] = useState("name-corner");
  const [logoClass, setLogoClass] = useState("logo-corner");
  const updateCurrentPage = () => {
    const scrollHeight = document.getElementById("main-box").scrollTop;
    const windowHeight = window.innerHeight;
    const pageNo = scrollHeight / windowHeight;
    setDarkMode(2.95 < pageNo && pageNo < 3.95);

    if (pageNo > 3.8) {
      setNameClass("name-center");
      setLogoClass("logo-center");
    } else {
      setNameClass("name-corner");
      setLogoClass("logo-corner");
    }
    setPrevScroll(scrollHeight);
  };
  useEffect(updateCurrentPage, [prevScroll]);

  return (
    <div className="main" id="main-box" onScroll={updateCurrentPage}>
      <Header darkMode={darkMode} nameClass={nameClass} logoClass={logoClass} />
      <div className="page page-intro d-flex flex-column justify-content-between">
        <Introduction />
      </div>
    </div>
  );
};
