import { Home } from "./Home";
import Resume from "./static/CameronWu_resume.pdf"
import { Routes, Route, Navigate } from 'react-router-dom';

export const CVPage = () => {
  const link = document.createElement("a");
  link.href = Resume;
  link.download = "CameronWu_Resume.pdf";
  link.click();
  return <Navigate to="/" />
}

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/CV" element={<CVPage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
};
