import "./Introduction.css";
import GradPhoto from "../static/GradPhoto.jpg";

export const Introduction = () => {
  return (
    <div className="d-flex justify-content-center flex-column align-items-center">
      <div className="introduction d-flex">
        <div className="intro-photo">
          <img src={GradPhoto} alt="Cameron Wu at Graduation" />
        </div>
        <div className="intro-text d-flex justify-content-start flex-column">
          <span className="nametag">Hello, I'm Cameron Wu</span>
          <span className="subtitle">
            Full-Stack Developer & Competitive Programmer <br />
          </span>
          <span className="job-subtitle">
            💼 Machine Learning Engineer, Canva <br />
            🎓 Master of Artifical Intelligence, University of Canterbury <br />
            🏆 NZ Programming Contest Winner, 2024
          </span>
        </div>
      </div>
      <div className="intro-description">
        I'm a software engineer currently based in Sydney, Australia. I have full
        stack experience across various frameworks, and have been working as a
        Machine Learning Engineer since February 2025. I have additionally won
        the NZ Programming contest in 2024 and competed in ICPC Regionals for
        the last two years. Feel free to download my{" "}
        <a
          href="/CV"
        >
          résumé
        </a>{" "}
        or contact me via{" "}
        <a href="https://www.linkedin.com/in/camwunz/" title="LinkedIn">
          LinkedIn
        </a>
        .
      </div>
    </div>
  );
};
